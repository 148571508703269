import styled from "styled-components";
import {MD_MAX_SIZE} from "../../../../../common/util/ViewSizeUtils";

export const StyledArtistContent = styled.div`

  height: 385px;
  background-color: white;
  margin-top: 35px;
  display: inline-grid;
  padding: 20px 60px;

  @media only screen and (max-width: ${900}px) {
    height: 480px;
    width: 355px;
    margin-top: 0;
  }

  @media only screen and (max-width: ${835}px) {
    height: unset;
    width: unset;
    max-width: 355px;
    margin-top: 0;
  }

  @media only screen and (max-width: ${MD_MAX_SIZE}px) {
    padding: 20px 20px;
  }
`;
