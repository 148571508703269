import styled from "styled-components";

export const StyledArtistGallery = styled.div`
  .image {
    height: 100px;

    @media only screen and (min-width: ${1382}px) {
      height: 120px;
    }

    @media only screen and (max-width: ${835}px) {
      height: 150px;
    }
  }
`;
