import React, {FC, PropsWithChildren} from "react";
import {StyledLinedContainer} from "./LinedContainer.style";

export interface LineProps {
    top: string;
    left: string;
    length: string;
}

export interface LinedContainerProps {
    topLine: LineProps;
    bottomLine: LineProps;
    leftLine: LineProps;
    rightLine: LineProps;

    linesWidth: string;
    linesColor: string;
}

export const LinedContainer: FC<PropsWithChildren<LinedContainerProps>> = (props: PropsWithChildren<LinedContainerProps>) => {
    return <StyledLinedContainer {...props}>
            <div className={"lined-container"}>
                {props.children}
                <div className={"line-top"}/>
                <div className={"line-bottom"}/>
                <div className={"line-left"}/>
                <div className={"line-right"}/>
            </div>
    </StyledLinedContainer>;
};
