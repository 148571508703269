import styled from "styled-components";
import {Colors} from "../../../../../common/style/ColorUtils";

export const StyledArtistTitle = styled.div`
  background-color: ${Colors.YELLOW};
  font-weight: 100;
  font-family: bely-bold;
  font-size: 30px;
  line-height: 60px;
  height: 60px;
  width: 250px;
  text-align: center;
  letter-spacing: 1px;
`;
