import styled from "styled-components";
import {Align} from "../service/Artists.type";
import {MD_MAX_SIZE} from "../../../common/util/ViewSizeUtils";

interface StyledArtistView {
    align: Align;
}

export const StyledArtistView = styled.div<StyledArtistView>`
  display: inline-flex;
  ${props => props.align === Align.LEFT ? "padding-left: 80px;" : "padding-right: 80px;"};

  @media only screen and (max-width: ${MD_MAX_SIZE}px) {
    ${props => props.align === Align.LEFT ? "padding-left: 30px;" : "padding-right: 30px;"};
  }

  @media only screen and (max-width: ${900}px) {
    display: inline-grid;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  padding-bottom: 120px;
  
  .xDD {
    display: flex;
    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      display: grid;
    }
  }
  
  .gallery-container {
    margin-top: auto;
  }
  
  .right-artist-title-container {
    text-align: right;
  }
  
  .social-media-container {
    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      grid-row: 2;
    }
  }

  .artist-title-desktop-container {
    @media only screen and (max-width: ${900}px) {
      display: none;
    }
  }

  .artist-title-mobile-container {
    @media only screen and (min-width: ${901}px) {
      display: none;
    }
    padding-bottom: 40px;
    padding-left: 15px;

    @media only screen and (max-width: ${835}px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .XDDD {
    width: 100%;
    display: inline-flex;
    @media only screen and (max-width: ${835}px) {
      display: inline-grid;
    }
  }
  
  .XDDDDDDD {
    @media only screen and (max-width: ${900}px) {
      margin-left: auto;
      padding-right: 15px;
    }

    @media only screen and (max-width: ${835}px) {
      margin-right: auto;
      margin-left: auto;
      padding-right: 0;
    }
  }

  .XDDDDDDD-right {
    @media only screen and (max-width: ${900}px) {
      margin-right: auto;
      padding-left: 15px;
    }

    @media only screen and (max-width: ${835}px) {
      margin-right: auto;
      margin-left: auto;
      padding-left: 0;
    }
  }
  
  .right-align-picture-container {
    @media only screen and (max-width: ${835}px) {
      grid-row: 1;
    }
  }
`;
