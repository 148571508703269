import styled from "styled-components";
import {Colors} from "../../../../../common/style/ColorUtils";

export const StyledArtistDescription = styled.div`
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
  font-size: 18px;
  font-family: bely;
  white-space: pre-wrap;
`;
