import React, {FC} from "react";
import {StyledArtistGallery} from "./ArtistGallery.style";
import {Carousel} from "../../../../../common/components/carousel/Carousel.component";
import {ImageCustom} from "../../../../../common/components/image/ImageCustom.component";
import {ImageProps} from "../../../../../common/components/carousel/service/Carousel.service";

interface ArtistGalleryProps {
    pictures: ImageProps[];
}

export const ArtistGallery: FC<ArtistGalleryProps> = (props: ArtistGalleryProps) => {
    return <StyledArtistGallery>
        <Carousel>
            {props.pictures.map(picture =>
                <ImageCustom key={picture.key} className={"image"} image={picture} gallery={props.pictures}/>
            )}
        </Carousel>
    </StyledArtistGallery>;
};
