import * as React from "react"
import {StyledArtists} from "./Artists.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";
import {getArtists} from "./service/Artists.service";
import {LeftArtistView} from "./artistView/LeftArtistView.component";
import {RightArtistView} from "./artistView/RightArtistView.component";

export const ArtistsComponent = () => {
    return <StyledArtists>
        <Title title={"ARTISTS"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        {getArtists().map((value, index) =>
            (index % 2 === 0)
                ? <LeftArtistView key={value.name} artist={value}/>
                : <RightArtistView key={value.name} artist={value}/>
        )}
    </StyledArtists>;
}
