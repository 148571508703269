import React, {FC} from "react";
import {StyledArtistView} from "./StyledArtistView.style";
import {Align, Artist, SocialMedia} from "../service/Artists.type";
import {ArtistProfilePicture} from "./artistComponents/artistProfilePicture/ArtistProfilePicture.component";
import {ArtistTitle} from "./artistComponents/artistTitle/ArtistTitle.component";
import {ArtistDescription} from "./artistComponents/artistDescription/ArtistDescription.component";
import {ArtistGallery} from "./artistComponents/artistGallery/ArtistGallery.component";
import {ArtistSocialMedia} from "./artistComponents/artistSocialMedia/ArtistSocialMedia.component";
import {ArtistContent} from "./artistComponents/artistsContent/ArtistContent.component";

interface LeftArtistViewProps {
    artist: Artist;
}

export const LeftArtistView: FC<LeftArtistViewProps> = (props: LeftArtistViewProps) => {
    return <StyledArtistView align={Align.LEFT}>
        <div className={"artist-title-mobile-container"}>
            <ArtistTitle title={props.artist.name}/>
        </div>
        <div className={"XDDD"}>
            <div>
                <ArtistProfilePicture picture={props.artist.profilePicture} align={Align.LEFT}/>
            </div>
            <div className={"XDDDDDDD"}>
                <div className={"artist-title-desktop-container"}>
                    <ArtistTitle title={props.artist.name}/>
                </div>
                <ArtistContent>
                    <div className={"xDD"}>
                        <div>
                            <ArtistDescription description={props.artist.description}/>
                        </div>
                        <div>
                            <ArtistSocialMedia
                                socialMedia={props.artist.socialMedia as SocialMedia[]}
                                align={Align.RIGHT}
                            />
                        </div>
                    </div>
                    <div className={"gallery-container"}>
                        <ArtistGallery pictures={props.artist.portfolio}/>
                    </div>
                </ArtistContent>
            </div>
        </div>
    </StyledArtistView>;
};
