import React, {FC} from "react";
import {StyledArtistSocialMedia} from "./ArtistSocialMedia.style";
import {Align, SocialMedia} from "../../../service/Artists.type";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ExternalLink} from "../../../../../common/components/externalLink/ExternalLink.component";

interface ArtistSocialMediaProps {
    socialMedia: SocialMedia[];
    align: Align;
}

export const ArtistSocialMedia: FC<ArtistSocialMediaProps> = (props: ArtistSocialMediaProps) => {
    return <StyledArtistSocialMedia align={props.align}>
        {props.socialMedia.map(value =>
            props.align === Align.RIGHT
                ? <ExternalLink key={value.link} to={value.link} className={"social-media-row"}>
                    <div className={"social-media-name"}>{value.name}</div>
                    <div className={"icon"}><FontAwesomeIcon icon={value.icon}/></div>
                </ExternalLink>
                : <ExternalLink key={value.link} to={value.link} className={"social-media-row"}>
                    <div className={"icon"}><FontAwesomeIcon icon={value.icon}/></div>
                    <div>{value.name}</div>
                </ExternalLink>
        )}
    </StyledArtistSocialMedia>;
};
