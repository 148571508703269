import React, {FC, PropsWithChildren} from "react";
import {StyledArtistContent} from "./ArtistContent.style";

interface ArtistContentProps {
}

export const ArtistContent: FC<PropsWithChildren<ArtistContentProps>> = (props: PropsWithChildren<ArtistContentProps>) => {
    return <StyledArtistContent>
        {props.children}
    </StyledArtistContent>;
};
