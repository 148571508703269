import React, {FC} from "react";
import {StyledArtistTitle} from "./ArtistTitle.style";
import {Translate} from "../../../../../lang/Translate.component";
import {LinedContainer} from "../../../../../common/components/linedContainer/LinedContainer.component";
import {LineProps} from "../../../../../common/components/buttonWithLines/ButtonWithLines.component";
import {Colors} from "../../../../../common/style/ColorUtils";

interface ArtistTitleProps {
    title: string;
}

export const ArtistTitle: FC<ArtistTitleProps> = (props: ArtistTitleProps) => {
    const topLineProps: LineProps = {
        left: "0", length: "280px", top: "0"
    };

    const bottomLineProps: LineProps = {
        left: "-35px", length: "285px", top: "60px"
    };

    const leftLineProps: LineProps = {
        left: "0", length: "100px", top: "-15px"
    };

    const rightLineProps: LineProps = {
        left: "250px", length: "90px", top: "-20px"
    };

    return <LinedContainer
        topLine={topLineProps}
        bottomLine={bottomLineProps}
        leftLine={leftLineProps}
        rightLine={rightLineProps}
        linesWidth={"1px"}
        linesColor={Colors.YELLOW}
    >
        <StyledArtistTitle>
            <Translate translationKey={props.title}/>
        </StyledArtistTitle>
    </LinedContainer>
};
