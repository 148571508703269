import React, {FC} from "react";
import {StyledArtistProfilePicture} from "./ArtistProfilePicture.style";
import {Align} from "../../../service/Artists.type";
import {ImageCustom} from "../../../../../common/components/image/ImageCustom.component";
import {ImageProps} from "../../../../../common/components/carousel/service/Carousel.service";

interface ArtistProfilePictureProps {
    picture: ImageProps;
    align: Align
}

export const ArtistProfilePicture: FC<ArtistProfilePictureProps> = (props: ArtistProfilePictureProps) => {
    return <StyledArtistProfilePicture align={props.align}>
            <ImageCustom className={"artist-profile-picture"} image={props.picture} />
    </StyledArtistProfilePicture>;
};
