import React, {FC, PropsWithChildren} from "react";
import {StyledCarousel} from "./Carousel.style";
import {Arrow} from "./arrow/Arrow.component.tsx";
import {RenderArrowProps} from "react-elastic-carousel";

interface CarouselProps {
}

export const Carousel: FC<PropsWithChildren<CarouselProps>> = (props: PropsWithChildren<CarouselProps>) => {
    const breakPoints = [
        {width: 1, itemsToShow: 1},
        {width: 300, itemsToShow: 2},
        {width: 500, itemsToShow: 3},
        {width: 700, itemsToShow: 4},
        {width: 900, itemsToShow: 5},
        {width: 1100, itemsToShow: 6}
    ];

    // @ts-ignore
    return <StyledCarousel
        outerSpacing={65}
        breakPoints={breakPoints}
        isRTL={false}
        renderPagination={() => <></>}
        renderArrow={(props: RenderArrowProps) => <Arrow  {...props}/>}
    >
        {props.children}
    </StyledCarousel>;
};
