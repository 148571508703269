import styled from "styled-components";
import {Align} from "../../../service/Artists.type";
import {MD_MAX_SIZE} from "../../../../../common/util/ViewSizeUtils";

export interface StyledArtistProfilePicture {
    align: Align
}

export const StyledArtistProfilePicture = styled.div<StyledArtistProfilePicture>`
  padding: 10px;
  height: 500px;
  max-width: 375px;
  background-color: white;
  ${props => props.align === Align.LEFT ? "margin-right: 80px" : "margin-left: 80px"};

  @media only screen and (max-width: ${MD_MAX_SIZE}px) {
    ${props => props.align === Align.LEFT ? "margin-right: 50px" : "margin-left: 50px"};
  }
  
  @media only screen and (max-width: ${900}px) {
    margin-right: 15px;
    margin-left: 15px;
  }

  @media only screen and (max-width: ${835}px) {
    margin-right: auto;
    margin-left: auto;
    height: auto;
  }

  img {
    width: calc(100vw - 20px);
    max-width: 375px;
  }
`;
