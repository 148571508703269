import styled from "styled-components";
import {LinedContainerProps} from "./LinedContainer.component";

export const StyledLinedContainer = styled.div<LinedContainerProps>`
  display: inline-grid;

  .lined-container {
    display: flex;
  }

  :hover .line-top {
    margin-left: ${(props: LinedContainerProps) => props.bottomLine.left};
    width: ${(props: LinedContainerProps) => props.bottomLine.length};
    transition: 0.6s;
  }

  :hover .line-bottom {
    margin-left: ${(props: LinedContainerProps) => props.topLine.left};
    width: ${(props: LinedContainerProps) => props.topLine.length};
    transition: 0.6s;
  }

  :hover .line-left {
    margin-top: ${(props: LinedContainerProps) => props.rightLine.top};
    height: ${(props: LinedContainerProps) => props.rightLine.length};
    transition: 0.6s;
  }

  :hover .line-right {
    margin-top: ${(props: LinedContainerProps) => props.leftLine.top};
    height: ${(props: LinedContainerProps) => props.leftLine.length};
    transition: 0.6s;
  }

  .line-top {
    position: absolute;
    height: ${(props: LinedContainerProps) => props.linesWidth};
    background-color: ${(props: LinedContainerProps) => props.linesColor};
    transition: 0.6s;

    width: ${(props: LinedContainerProps) => props.topLine.length};
    margin-top: ${(props: LinedContainerProps) => props.topLine.top};
    margin-left: ${(props: LinedContainerProps) => props.topLine.left};
  }

  .line-bottom {
    position: absolute;
    height: ${(props: LinedContainerProps) => props.linesWidth};
    background-color: ${(props: LinedContainerProps) => props.linesColor};
    transition: 0.6s;

    width: ${(props: LinedContainerProps) => props.bottomLine.length};
    margin-top: ${(props: LinedContainerProps) => props.bottomLine.top};
    margin-left: ${(props: LinedContainerProps) => props.bottomLine.left};
  }

  .line-left {
    width: ${(props: LinedContainerProps) => props.linesWidth};
    position: absolute;
    background-color: ${(props: LinedContainerProps) => props.linesColor};
    transition: 0.6s;

    height: ${(props: LinedContainerProps) => props.leftLine.length};
    margin-top: ${(props: LinedContainerProps) => props.leftLine.top};
    margin-left: ${(props: LinedContainerProps) => props.leftLine.left};
  }

  .line-right {
    width: ${(props: LinedContainerProps) => props.linesWidth};
    position: absolute;
    background-color: ${(props: LinedContainerProps) => props.linesColor};
    transition: 0.6s;

    height: ${(props: LinedContainerProps) => props.rightLine.length};
    margin-top: ${(props: LinedContainerProps) => props.rightLine.top};
    margin-left: ${(props: LinedContainerProps) => props.rightLine.left};
  }
`;
