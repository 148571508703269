import React, {FC} from "react";
import {StyledArtistDescription} from "./ArtistDescription.style";
import {Translate} from "../../../../../lang/Translate.component";

interface ArtistDescriptionProps {
    description: string
}

export const ArtistDescription: FC<ArtistDescriptionProps> = (props: ArtistDescriptionProps) => {
    return <StyledArtistDescription>
        <Translate translationKey={props.description}/>
    </StyledArtistDescription>;
};
