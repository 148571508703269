import styled from "styled-components";
import {Align} from "../../../service/Artists.type";
import {Colors} from "../../../../../common/style/ColorUtils";
import {LG_MIN_SIZE, MD_MAX_SIZE} from "../../../../../common/util/ViewSizeUtils";

interface StyledArtistSocialMediaProps {
    align: Align;
}

export const StyledArtistSocialMedia = styled.div<StyledArtistSocialMediaProps>`
  text-align: ${props => props.align === Align.RIGHT ? "right" : "left"};
  font-family: bely-bold;

  @media only screen and (max-width: ${835}px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media only screen and (min-width: ${LG_MIN_SIZE}px) {
    padding-left: 20px;
    min-width: 230px;
    display: table-caption;
  }

  .icon {
    font-size: 39px;
    line-height: 39px;
    ${props => props.align === Align.RIGHT ? "margin-left: 13px;" : "margin-right: 13px;"};

    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      margin-left: 13px;
      margin-right: 13px;
      font-size: 27px;
      line-height: 27px;
    }
  }

  a {
    color: ${Colors.BLACK};
    font-size: 20px;
    line-height: 39px;

    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      font-size: 15px;
      line-height: 27px;
    }
  }

  .social-media-row {
    display: inline-flex;
    transition: all 0.3s ease-in-out;
    transform: scale(1, 1);
  
    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      width: 50%;
    }

    @media only screen and (max-width: ${835}px) {
      width: 100%;
    }
  }

  .social-media-row:hover {
    transform: scale(1.04, 1.04)
  }
  
  .social-media-name {
    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      margin-left: auto;
    }
  }
`;
