import {Artist} from "./Artists.type";
import {Zoya} from "./Zoya.service";
import {Samoleczenie} from "./Samoleczenie.service";
import {Axellent} from "./Axellent.service";
import {Cyzana} from "./Cyzana.service";
import {Tala} from "./Tala.service";
import {Gabi} from "./Gabi.service";

export const getArtists = (): Artist[] => [
    Zoya,
    Samoleczenie,
    Axellent,
    Cyzana,
    Tala,
    Gabi
];
