import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";

export interface Artist {
    name: string;
    description: string;
    profilePicture: ImageProps;
    portfolio: ImageProps[];
    socialMedia: SocialMedia[];
}

export interface SocialMedia {
    icon: IconProp;
    name: string;
    link: string;
}

export enum Align {
    RIGHT = "RIGHT",
    LEFT = "LEFT"
}